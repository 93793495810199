<nav class="mx-auto max-w-7xl">
	<div class="relative my-8 flex flex-col items-center justify-center space-y-6">
		<div>
			<img
				class="dropshadow w-72"
				src="/images/svelte-weekly-horizontal.svg"
				alt="svelte weekly logo"
			/>
		</div>
		<div class="flex space-x-6 text-gray-400">
			<a href="https://newsletter.svelteweekly.com/#archive">issues</a>
			<a href="/links">links</a>
			<a href="/about">about</a>
		</div>
	</div>
</nav>

<style>
	.dropshadow {
		filter: drop-shadow(1px 1px 3px #676778);
	}
</style>
