<script>
	import dayjs from 'dayjs';

	import Button from '$elements/html/Button.svelte';
	import Input from '$elements/html/Input.svelte';
</script>

<footer class="bg-gray-100" aria-labelledby="footer-heading">
	<h2 id="footer-heading" class="sr-only">Footer</h2>

	<div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
		<div
			class="md: mb-12 flex flex-col items-center justify-center space-x-4 space-y-4 md:flex-row md:justify-start md:space-y-0"
		>
			<img src="/favicon.png" class="h-10" />
			<h3 class="text-lg font-semibold uppercase tracking-wider text-svelte-blue">Svelte Weekly</h3>
		</div>
		<div class="xl:grid xl:grid-cols-3 xl:gap-8">
			<div class="mb-12 grid grid-cols-2 gap-8 xl:col-span-2">
				<div class="flex flex-col items-center justify-center space-y-6">
					<a
						href="https://newsletter.svelteweekly.com/#archive"
						class="font-semibold uppercase tracking-wider"
					>
						Past Issues
					</a>
					<a href="/links" class="font-semibold uppercase tracking-wider"> Svelte Links </a>
				</div>
				<div class="flex flex-col items-center justify-center space-y-6">
					<a href="/about" class="font-semibold uppercase tracking-wider"> About / FAQ </a>
					<a href="/contact-us" class="font-semibold uppercase tracking-wider"> Contact Us </a>
				</div>
			</div>

			<div class="mt-8 xl:mt-0">
				<h3 class="text-sm font-semibold uppercase tracking-wider text-gray-400">
					Subscribe Today
				</h3>
				<p class="mt-4 text-base text-gray-500">
					The latest Svelte news, articles, and resources, sent to your inbox weekly.
				</p>

				<form
					action="https://newsletter.svelteweekly.com/add_subscriber"
					method="post"
					id="revue-form"
					name="revue-form"
					target="_blank"
					class="mt-4 sm:flex sm:max-w-md"
				>
					<label for="email-address" class="sr-only">Email address</label>
					<Input
						type="email"
						name="member[email]"
						id="member_email"
						autocomplete="email"
						required
						placeholder="Enter your email"
					/>
					<div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
						<Button type="submit">Subscribe</Button>
					</div>
				</form>
			</div>
		</div>
		<div class="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
			<div class="flex space-x-6 md:order-2">
				<a href="https://twitter.com/unspecifiedio" class="text-gray-400 hover:text-gray-500">
					<span class="sr-only">Twitter</span>
					<svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
						<path
							d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
						/>
					</svg>
				</a>

				<a
					href="https://github.com/HelloUnspecified/SvelteWeekly.com"
					class="text-gray-400 hover:text-gray-500"
				>
					<span class="sr-only">GitHub</span>
					<svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
						<path
							fill-rule="evenodd"
							d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
							clip-rule="evenodd"
						/>
					</svg>
				</a>
			</div>
			<p class="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
				&copy; {dayjs().format('YYYY')} Unspecified Software Co. All rights reserved.
			</p>
		</div>
	</div>
</footer>
